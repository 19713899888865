<template>
  <div class="center">
    <v-card style="background-color: #e8eaf6" class="vcard" hover>
      <center><img class="img" src="../assets/Logo/test.png" alt="System Logo" /></center>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text>
          <v-text-field 
            clearable
            rounded
            color="#1A237E"
            v-model="username"
            :label="$t('Login.email')"
            outlined
            dense
            prepend-inner-icon="mdi-account"
            :rules="emailRules"
            required
            tabindex="1"
          > 
          </v-text-field>
          <v-text-field 
            clearable
            rounded
            color="#1A237E"
            v-model="password"
            :label="$t('Login.password')"
            prepend-inner-icon="mdi-lock"
            :append-icon="show == true ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show == true ? 'text' : 'password'"
            @click:append="show = !show"
            outlined
            dense
            tabindex="2"
            @keypress.enter="!valid || !username || !password ? '' : test()"
          > 
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="!valid || !username || !password" @click="test()"  outlined :loading="loading" block color="#0D47A1">
            {{$t('Login.login')}}
          </v-btn>
        </v-card-actions>
        <div class="text-left mt-3">
          <center>
            <span>{{ $t("Login.language") }}: </span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" small text outlined v-on="on" color="#FFFAFA">
                  <h5 style="color: #0d47a1;">{{ `${$i18n.locale}` }}</h5></v-btn
                >
              </template>
              <v-list>
                <v-list-item @click="translate('ja')">
                  <v-list-item-title>
                    <div class="d-flex align-center">
                      <img src="../../public/img/flags/japan_64.png" style="width: 34px;">
                      <div class="ml-2">
                        <span class="d-block text-center">JA</span>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="translate('en')">
                  <v-list-item-title>
                    <div class="d-flex align-center">
                      <img src="../../public/img/flags/us_64.png" style="width: 34px;">
                      <div class="ml-2">
                        <span class="d-block text-center">EN</span>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </center>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import DataTransform from '../assets/class/DataTransform.js'
import axios from "axios";
import Swal from "sweetalert2";
import md5 from 'md5'
export default {
  data() {
    return {
      dataTransform: new DataTransform(),
      lang: "",
      userGuide: false,
      show: "",
      holder: {},
      confirmPass: "",
      username: "",
      password: "",
      loading: false,
      valid: true,
      emailRules: [
        v => !v || v.trim() === '' || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || '無効なメール。'
      ]
    };
  },
  methods: {
    openUserGuideDialog() {
      this.userGuide = true;
    },
    closeUserGuideDialog() {
      this.userGuide = false;
    },
    translate(val) {
      this.lang = val;
      this.$i18n.locale = val;
    },
    comparePasswords(userPassword, Password) {
      const hashedUserPassword = md5(userPassword); // Hash the password provided by the user
      return hashedUserPassword === Password;
    },
    formatEmpCode(empCode) {
      if(empCode){
        if (empCode.length < 5) {
          const zerosToAdd = 5 - empCode.length;
          const formattedEmpCode = '0'.repeat(zerosToAdd) + empCode;
          return formattedEmpCode;
        } else {
          return empCode;
        }
      }else{
        return empCode
      }
    },
    insertNewUser(data){
      axios.post(`${this.aws}insertPlannerCredential`, data, this.apiKey).then((res) => {
        if(res.data){
          this.loading = false;
        }
      }).catch((error) => {
        console.error('Error inserting user:', error);
        this.loading = false;
      });
    },
    test(){
      this.loading = true;
      axios.post(`${this.aws}getPlannerCredentials`,{ username: this.username }, this.apiKey).then((res) => {
        if(res.data.length > 0){
          if(this.comparePasswords(this.password, res.data[0].Password)){
            const userData = res.data.map(x => {
              return {
                Emp_code: this.formatEmpCode(x.Emp_code),
                Mail_Address: x.Mail_Address,
                Name: x.Name,
                User_Category: x.User_Category,
                pageReloaded: true
              };
            });
            this.loading = false;
            if(this.password == 'ichijo'){
              userData[0].Default = 'true'
            }
            this.LoginAlert();
            this.$store.commit("STORE_USERINFO", this.dataTransform.toEncrypt(userData[0]));
            this.$router.push("/");
          }else{
            this.loading = false;
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${
                this.$i18n.locale == "ja"
                  ? "パスワードが正しくありません。"
                  : "Incorrect password."
              }`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }else{
          axios.get(`${this.DSS_api}getAllUsers`, this.DSS_apiKey).then(res2 => {
          const { data } = res2;
          const userInfo = data.find(x => x.MailAddress == this.username)
          // console.log(userInfo);
          if(userInfo){
            if(this.comparePasswords(this.password, userInfo.Password)){
              let obj = {
                Emp_code: this.formatEmpCode(userInfo.UserId),
                Mail_Address: userInfo.MailAddress,
                Name: userInfo.Name,
                Password: userInfo.Password,
                User_Category: userInfo.accType ? userInfo.accType : userInfo.isGCUser ? 'GC User' : null,
                pageReloaded: true
              }
              this.insertNewUser(obj)
              this.LoginAlert();
              this.$store.commit("STORE_USERINFO", this.dataTransform.toEncrypt(obj));
              this.$router.push("/");
            }else{
              this.login()
            }
          }else{
            this.login()
          }
          })
        }
      })
    },
    login() {
      if (!this.username || !this.password) {
        Swal.fire({
          icon: "warning",
          title: `${
            this.$i18n.locale == "ja"
              ? "すべてのフィールドに入力してください "
              : "Please fill in all fields"
          }`,
        });
        return false;
      }
      this.loading = true;
      axios.post(`${this.aws}getAdmin`,{ username: this.username, password: this.password }, this.apiKey).then((res) => {
          if (res.data.length > 0) {
            axios.get(`${this.DSS_api}getAllUsers`, this.DSS_apiKey).then(res2 => {
              const { data } = res2;
              const userInfo = data.find(x => res.data[0].user_category === 'ICHIJO' ? res.data[0].email === x.MailAddress : res.data[0].mail_address === x.MailAddress);
              // console.log(userInfo, '1');
              let obj = {
                Emp_code: userInfo ? this.formatEmpCode(userInfo.UserId) : this.formatEmpCode(res.data[0].emp_code),
                Mail_Address: userInfo ? userInfo.MailAddress : res.data[0].mail_address,
                Name: userInfo ? userInfo.Name : res.data[0].full_name,
                Password: md5(this.password),
                User_Category: userInfo ? userInfo.accType ? userInfo.accType : userInfo.isGCUser ? 'GC User' : null : res.data[0].user_category,
                pageReloaded: true
              }
              this.insertNewUser(obj)
              if(this.password == 'ichijo'){
                obj.Default = 'true'
              }
              this.LoginAlert();
              this.$store.commit("STORE_USERINFO", this.dataTransform.toEncrypt(obj));
              this.$router.push("/");
            })
          } else {
            axios.post(`${this.aws}getUsers`,{ username: this.username, password: this.password },this.apiKey).then((res) => {
              // console.log(res.data);
                if (res.data.length > 0) {
                  axios.get(`${this.DSS_api}getAllUsers`, this.DSS_apiKey).then(res2 => {
                    const { data } = res2;
                    const userInfo = data.find(x => res.data[0].user_category === 'ICHIJO' || res.data[0].user_category === 'GC' ? res.data[0].email === x.MailAddress : res.data[0].mail_address === x.MailAddress);
                    // console.log(userInfo, '2');
                    let obj = {
                      Emp_code: userInfo ? this.formatEmpCode(userInfo.UserId) : this.formatEmpCode(res.data[0].empcode),
                      Mail_Address: userInfo ? userInfo.MailAddress : res.data[0].email,
                      Name: userInfo ? userInfo.Name : res.data[0].name,
                      Password: md5(this.password),
                      User_Category: userInfo ? userInfo.accType ? userInfo.accType : userInfo.isGCUser ? 'GC User' : null : res.data[0].user_category + ' User',
                      pageReloaded: true
                    }
                    this.insertNewUser(obj)
                    if(this.password == 'ichijo'){
                      res.data[0].Default = 'true'
                    }
                    res.data[0].MailAddress = userInfo ? userInfo.MailAddress : null,
                    res.data[0].Emp_code = userInfo ? userInfo.UserId : null,
                    res.data[0].Name = userInfo ? userInfo.Name : null,
                    res.data[0].User_Category = res.data ? res.data[0].user_category + ' User' : 'GC User'
                    this.loading = false;
                    this.LoginAlert();
                    this.$store.commit("STORE_USERINFO", this.dataTransform.toEncrypt(res.data[0]));
                    this.$router.push("/");
                  })
                } else {
                  axios.post( `${this.aws}login`,{ username: this.username, password: this.password },this.apiKey).then((res2) => {
                      if (res2.data.length > 0) {
                        axios.get(`${this.DSS_api}getAllUsers`, this.DSS_apiKey).then(res => {
                          const { data } = res;
                          const userInfo = data.find(x => res2.data[0].user_category ? res2.data[0].user_category === 'ICHIJO' || res.data[0].user_category === 'GC' ? res2.data[0].email === x.MailAddress : res2.data[0].mail_address === x.MailAddress : res2.data[0].email === x.MailAddress);
                          let obj = {
                            Emp_code: userInfo ? this.formatEmpCode(userInfo.UserId) : this.formatEmpCode(res2.data[0].empcode),
                            Mail_Address: userInfo ? userInfo.MailAddress : res2.data[0].email,
                            Name: userInfo ? userInfo.Name : res2.data[0].name,
                            Password: md5(this.password),
                            User_Category: userInfo ? userInfo.accType ? userInfo.accType : userInfo.isGCUser ? 'GC User' : null : 'Ichijo User',
                            pageReloaded: true
                          }
                          this.insertNewUser(obj)
                          if(this.password == 'ichijo'){
                            res2.data[0].Default = 'true'
                          }
                          res2.data[0].MailAddress = userInfo ? userInfo.MailAddress : null,
                          res2.data[0].Emp_code = userInfo ? userInfo.UserId : null,
                          res2.data[0].Name = userInfo ? userInfo.Name : null,
                          res2.data[0].User_Category = res2.data ? res2.data[0].user_category ?  res2.data[0].user_category + ' User' : 'Ichijo User' : 'Ichijo User'
                          this.loading = false;
                          this.LoginAlert();
                          // res2.data[0].user_category = "ICHIJO";
                          this.$store.commit("STORE_USERINFO", this.dataTransform.toEncrypt(res2.data[0]));
                          this.$router.push("/");
                          // console.log(res2.data[0], '3');
                        })
                      } else {
                        this.loading = false;
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `${
                              this.$i18n.locale == "ja"
                              ? "パスワードが正しくありません。"
                              : "Incorrect password."
                            }`,
                            showConfirmButton: false,
                            timer: 2000,
                        });
                      }
                    });
                }
              });
          }
        });
    },
    async login2() {
      try {
        const params = new URLSearchParams(window.location.search);
        this.username = params.get('index');
        this.password = params.get('id');
        if(this.username && this.password){
          // console.log(`http://10.169.140.119:3001/users/getIchijoUsers/${this.username}/${this.password}`)
          const res = await axios.get(`${this.gcAWS}users/getIchijoUsers/${this.username}/${this.password}`, this.gcAPI_Key);
          if(res.data.length > 0){
            axios.get(`${this.DSS_api}getAllUsers`, this.DSS_apiKey).then(res2 => {
              const { data } = res2;
              const userInfo = data.find(x => res.data[0].user_category === 'ICHIJO' || res.data[0].user_category === 'GC' ? res.data[0].email === x.MailAddress : res.data[0].mail_address === x.MailAddress);
              let obj = {
                Emp_code: userInfo ? this.formatEmpCode(userInfo.UserId) : this.formatEmpCode(res.data[0].UserId),
                Mail_Address: userInfo ? userInfo.MailAddress : res.data[0].MailAddress,
                Name: userInfo ? userInfo.Name : res.data[0].Name,
                Password: md5(this.password),
                User_Category: userInfo ? userInfo.accType ? userInfo.accType : userInfo.isGCUser ? 'GC User' : null : res.data[0].company_group + ' User',
                pageReloaded: true
              }
              this.insertNewUser(obj)
              if(this.password == 'ichijo'){
                obj.Default = 'true'
              }
              this.$store.commit("STORE_USERINFO", this.dataTransform.toEncrypt(obj));
              this.$router.push("/");
            })
          }
        }

      } catch (error) {
        alert("Error in login2:", error);
        // Handle the error as needed, e.g., show an error message to the user
      }
    },
    LoginAlert() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: `${
          this.$i18n.locale == "ja"
            ? "正常にサインインしました "
            : "Signed in successfully"
        }`,
      });
    },
  },
  mounted() {
    this.login2()
  },
};
</script>

<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.vcard {
  width: 100%;
  max-width: 400px;
  padding: 1%;
  margin: 10%;
}

.img {
  display: block;
  height: auto;
  max-height: 25vh;
  max-width: 100%;
  margin: 0 auto;
}
@media (min-width: 1200px) {
  .vcard {
    margin: 20px;
  }
}
</style>
